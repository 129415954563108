<template>
  <div>
    <a-modal
      width="min(90%, 626px)"
      :footer="null"
      :closable="false"
      :visible="visible"
      @onCancel="cancel">
      <div class="add-group-modal">
        <div class="group-title">
          <h3 class="txt-32 txt-bold inner">
            {{ $t('components.titles.addStandardizationGroup') }}
          </h3>
          <p>
            {{ $t('components.description.multipleStandardizationTestGroups') }}
          </p>
        </div>
        <div>
          {{ $t('components.titles.standardizationGroupTitle') }}
          <a-input
            v-model="groupTitle"
            :placeholder="$t('components.description.exampleGroup')"
            style="height: 50px"/>
        </div>
        <div class="footer">
          <div style="margin-left: auto;">
            <a-button
                :disabled="isLoading"
                class="w170 TabButtonStandardization"
                type="default"
                size="large"
                @click="cancel">
              {{ $t('values.cancel') }}
            </a-button>
            <a-button
                :disabled="groupTitle.trim().length == 0 || isLoading"
                :loading="isLoading"
                class="w170 TabButtonStandardization"
                type="primary"
                size="large"
                @click="onAddClick">
              {{ $t('components.titles.createGroup') }}
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
    <div>
      <a-button
        type="link"
        class="txt-18 txt-black pl-0"
        :disabled="isLoading || isSupervisor"
        data-cy="enumerators-add-standardization-group-button"
        @click="visible = true">
        <a-icon type="edit" theme="filled" :class="{ 'txt-orange': !isSupervisor }"/>
        {{ $t('components.titles.addStandardizationGroup') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';


export default {
  name: 'Standardization',
  data() {
    return {
      visible: false,
      groupTitle: '',
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      metadata: state => state.survey.metadata,
      surveyId: state => state.survey.surveyId,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    })
  },
  methods: {
    ...mapActions([
      'createStandardizationGroupsForSurvey'
    ]),
    onAddClick() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      this.createStandardizationGroupsForSurvey({
        surveyId: this.surveyId,
        data: { title: this.groupTitle }
      }).then((createdGroup) => {
        this.visible = false;
        this.$router.push({
          name: 'Group',
          params: {
            groupSelected: createdGroup,
            groupId: createdGroup.id.toString(),
            activeTab: 'add-enumerators'
          }
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },
    cancel() {
      if (this.isLoading) {
        return;
      }
      this.visible = false;
    }
  }
};
</script>

<style lang="scss">
.TabButtonStandardization {
  width: 145px;
  height: 48px;
  padding: 9px 4px 9px 13px;
  border-radius: 1px;
  margin-left: 10px;
}
.add-group-modal {
  margin: 20px;
}
.group-title {
  text-align: center;
  margin-bottom: 40px;
}
.inner {
  display: table;
  margin: 0 auto;
  margin-bottom: 30px;
}
.footer {
  display: flex;
  margin-top: 40px;
}
</style>
